import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { Select } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RoiIncome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [userid, setUserId] = useState("");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [status_val, setStatus] = useState("0");

  const ROIincome_API = async () => {
    try {
      let responce = await API?.post(
        `/roiIncomeReport`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("roi", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          sid: item.sid,
          selfbv: item.selfbv,
          teambv: item.teambv,
          amount: item.InvestmentAmount,
          // status: item.status === 1 ? "Active" : "Inactive",
          date: item.dd,
          income: item.income,
          // Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
          //   item.wallet.length - 4
          // )}`,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    ROIincome_API();
  }, []);

  const handleChange_status = (value) => {
    setStatus(value);
  };

  const numberValidation = (e) => {
    const news = e.replace(/[^0-9]/, "").substring(0, 6);
    setUserId(news);
  };

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "From Id", accessor: "fromid" },
      // { Header: "Name", accessor: "f_name" },
      // { Header: "Slot", accessor: "on_amount" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Income", accessor: "income" },
      // { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "date" },
    ],
  });
  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div class="main-content app-content">
          {/* <!-- container --> */}
          <div class="main-container container-fluid">
            {/* <!-- breadcrumb --> */}
            <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                <span class="main-content-title mg-b-0 mg-b-lg-1">
                  ROC Earning
                </span>
              </div>
            </div>
            {/* <!-- /breadcrumb --> */}

            <div class="row">
              <div class="row mt-3">
                {/* <div class="col-md-2">
                  <label> User Id</label>
                  <input
                    type="text"
                    name="from_date"
                    id="uid"
                    onChange={(e) => numberValidation(e.target.value)}
                    class="form-control"
                    placeholder="Enter User Id"
                    value={userid}
                  />
                </div>

                <div class="col-md-2 mt-3">
                  <div className="mtr_space"></div>
                  <input
                    type="submit"
                    name="to_date"
                    value="Search"
                    class="btn btn-primary mt_5"
                  />
                </div> */}
              </div>
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div
                        id="myAssets_wrapper"
                        class="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div class="row">
                          <div class="col-sm-12">
                            <Table
                              data={[...currentPost]}
                              columns={matching_income.cols}
                            />
                            <Table_Buttons
                              indexOfFirstPage={indexOfFirstPage}
                              indexOfLastPost={indexOfLastPost}
                              setcurrentPage={setcurrentPage}
                              currentPage={currentPage}
                              totalData={referralApi.length}
                              listPerpage={listPerpage}
                              handleRowsPerPageChange={setlistPerpage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- row closed --> */}
          </div>
          {/* <!-- /Container --> */}
        </div>

        {/* <!---footer----> */}
        <Footer />
        {/* <!---end----></div> */}
      </div>
    </>
  );
}
