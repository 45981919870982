import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";

export default function Activate_History() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const ActiveHistory_API = async () => {
    try {
      let responce = await API?.post(
        `/activationHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          pool: "1",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Ahistory", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          Amount: item.Amount,
          remark: item.remark,
          type: item.type,
          dd: item.dd,
          transactionHash: (
            <a
              href={`https://bscscan.com/tx/${item.pinnumber}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#ffff" }}
            >
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    ActiveHistory_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Investment", accessor: "Amount" },
      { Header: " Remark", accessor: "remark" },
      { Header: "Txn", accessor: "transactionHash" },
      { Header: "Date & Time", accessor: "dd" },
    ],
  });

  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div class="horizontalMenucontainer">
          <a
            href="#top"
            id="back-to-top"
            class="back-to-top rounded-circle shadow"
          >
            <i class="angle fe fe-chevron-up"></i>
          </a>
          {/* <!-- Loader -->  */}
          <div id="global-loader" style={{ display: "none" }}>
            <img src="assets/img/loader.svg" class="loader-img" alt="Loader" />
          </div>
          {/* <!-- /Loader --> <!-- Page -->  */}
          <div class="page">
            <div class="layout-position-binder">
              {/* <!-- main-sidebar -->  */}
            </div>

            <div class="main-content app-content">
              {/* <!-- container --> */}
              <div class="main-container container-fluid">
                {/* <!-- breadcrumb --> */}
                <div class="breadcrumb-header justify-content-between">
                  <div class="left-content">
                    <span class="main-content-title mg-b-0 mg-b-lg-1">
                      Activation History{" "}
                    </span>
                  </div>
                </div>
                {/* <!-- /breadcrumb --> */}

                {/* <!-- /breadcrumb --> */}

                <div class="row">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        {/* <div class="row mt-3">
                          <div class="col-md-2">
                            <label> User Id</label>
                            <input
                              type="text"
                              name="from_date"
                              id="uid"
                              class="form-control"
                              placeholder="Enter User Id"
                            />
                          </div>
                          <div class="col-md-2 mt-2">
                            <div className="mtr_space"></div>
                            <input
                              type="submit"
                              name="to_date"
                              value="Search"
                              class="btn btn-primary mt_5"
                            />
                          </div>
                        </div> */}
                        <div class="table-responsive">
                          <div
                            id="myAssets_wrapper"
                            class="dataTables_wrapper dt-bootstrap5 no-footer"
                          >
                            <div class="row">
                              <div class="col-sm-12">
                                <Table
                                  data={[...currentPost]}
                                  columns={matching_income.cols}
                                />
                                <Table_Buttons
                                  indexOfFirstPage={indexOfFirstPage}
                                  indexOfLastPost={indexOfLastPost}
                                  setcurrentPage={setcurrentPage}
                                  currentPage={currentPage}
                                  totalData={referralApi.length}
                                  listPerpage={listPerpage}
                                  handleRowsPerPageChange={setlistPerpage}
                                />
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- row closed --> */}
              </div>
              {/* <!-- /Container --> */}
            </div>

            {/* <!---footer----> */}
            <Footer />
            {/* <!---end----> */}
          </div>
        </div>
      </div>
    </>
  );
}
