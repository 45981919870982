import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

export default function RewardIncome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const CTO_API = async () => {
    try {
      let responce = await API?.post(
        `/Reward_report_Running`,
        {
          uid: userId,
          fdate: "",
          tdate: ""
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("cto", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.id,
          uid: item.uid,
          lvl: item.lvl,
          req_Business: item.req_Business,
          Salery: item.Salery,
          Business: item.Business,
          Ac_status: item.Ac_status,
          totalsalerypaid: item.totalsalerypaid,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    CTO_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "lvl", accessor: "lvl" },
       { Header: "Required Business", accessor:"req_Business" },
      { Header: "Business", accessor: "Business" },
      { Header: "Reward", accessor: "Salery" },
      { Header: "status", accessor: "Ac_status" },
      { Header: "Reward Paid", accessor: "totalsalerypaid" },
    ],
  });
  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div class="main-content app-content">
          {/* <!-- container --> */}
          <div class="main-container container-fluid">
            {/* <!-- breadcrumb --> */}
            <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                <span class="main-content-title mg-b-0 mg-b-lg-1">
                 REWARD REPORT
                </span>
              </div>
              {/* <div class="justify-content-center mt-2">
                <ol class="breadcrumb breadcrumb-style3">
                  <li class="breadcrumb-item tx-15">
                    <a href="#">All Income</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Royalty Income
                  </li>
                </ol>
              </div> */}
            </div>
            {/* <!-- /breadcrumb --> */}

            <div class="row">
              <div class="row mt-3"></div>
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div
                        id="myAssets_wrapper"
                        class="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div class="row">
                          <div class="col-sm-12">
                            <Table
                              data={[...currentPost]}
                              columns={matching_income.cols}
                            />
                            <Table_Buttons
                              indexOfFirstPage={indexOfFirstPage}
                              indexOfLastPost={indexOfLastPost}
                              setcurrentPage={setcurrentPage}
                              currentPage={currentPage}
                              totalData={referralApi.length}
                              listPerpage={listPerpage}
                              handleRowsPerPageChange={setlistPerpage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- row closed --> */}
          </div>
          {/* <!-- /Container --> */}
        </div>

        {/* <!---footer----> */}
        <Footer />
        {/* <!---end----></div> */}
      </div>
    </>
  );
}
