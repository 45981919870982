import React from "react";

export default function Footer() {
  return (
    <>
      <div className="main-footer">
        <div className="col-lg-12 col-sm-12 text-center">
          <div className="container-fluid pd-t-0 ht-100p">
            {" "}
            Copyright © 2024{" "}
            <a href="#" className="tx-primary">
              Celex World
            </a>
            <a href="#"> </a> All rights reserved{" "}
          </div>
        </div>
      </div>
    </>
  );
}
