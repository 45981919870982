import React from "react";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar.jsx";
import { API } from "../../Utility/API";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { Select } from "antd";

function Withdrawal() {
  let [Amount, setAmount] = useState("0");
  let [Token, setToken] = useState("0");
  let [withdrawalType, setwithdrawalType] = useState("Select Withdrawal Type");
  let [paymentType, setPaymentType] = useState("Select");
  const [loader, setloader] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpvalid, setOTPValid] = useState("0");
  const [TotalWithdrawl, setTotalWithdrawl] = useState(0);
  const [Netbal, setNetBal] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [waddress, setwaddress] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [Modaldata, setModalData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const numberValidation = async (e) => {
    const newValues = e.replace(/[^0-9]/gi, "").substring(0, 6);
    setAmount(newValues);

    try {
      let responce = await API?.post(`/getUsdToTokenValue`, {
        TokenValue: 0,
        EthValue: 0,
        USDValue: newValues,
        type: "Buy",
      });
      responce = responce.data.data[0][0].Token;
      setToken(responce);
      console.log("netbalance", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (e.response.status === 401) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Net_Balance = async () => {
    try {
      let responce = await API?.get(
        `/userDashboard?uid=${userId}`,

        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("netbalance", responce.data.data[0][0]);
      responce = responce.data.data[0][0].Net_Balance;
      setNetBal(responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Withdrawal_API = async () => {
    try {
      let responce = await API?.post(
        `/withdrawal`,
        {
          uid: userId,
          address: "",
          amount: Amount,
          tokenvalue: Token,
          withdrawalType: withdrawalType,
          payment_type: paymentType,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("result", responce.data.data);
      responce = responce.data.data;
      if (responce === "SUCCESSFUL") {
        alert(responce);
        window.location.reload();
      } else {
        alert(responce);
      }
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    //Net_Balance();
  }, []);

  const handleType = async (value) => {
    setwithdrawalType(value);
    try {
      let responce = await API?.post(
        `/getNetBalance`,
        {
          uid: userId,
          type: value,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data;
      setNetBal(responce);
      //console.log("netbalance", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (e.response.status === 401) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const handlePayment = async (value) => {
    setPaymentType(value);
  };

  return (
    <div className="nft_theme">
      <Sidebar />
      <div class="main-content app-content">
        {/* <!-- container --> */}
        <div class="main-container container-fluid">
          {/* <!-- breadcrumb --> */}
          <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
              <span class="main-content-title mg-b-0 mg-b-lg-1">
                {" "}
                Withdrawal
              </span>
            </div>
            {/* <div class="justify-content-center mt-2">
              <ol class="breadcrumb breadcrumb-style3">
                <li class="breadcrumb-item tx-15">
                  <a href="#">Withdrawal</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Withdrawal
                </li>
              </ol>
            </div> */}
          </div>
          {/* <!-- /breadcrumb --> */}

          <div class="col-12 d-flex justify-content-center py-5 ">
            <div class="col-md-6 col-lg-5 col-xxl-4 col-10 width-mg card1">
              <div
                class="d-flex flex-column align-items-center profile-border profile-login  py-4 shadow-withdraw"
                id="withdraw-inp"
              >
                <h2 class="h-color col-12 py-2 px-4 border_bottom">
                  {" "}
                  Withdrawal
                </h2>
                {/* <div class="col-11 mt-3 row align-items-center">
                  <p class="col-5 m-0 p-0">Withdrawal Address</p>
                  <input type="text" class="p-2 profile-border col-7" />
                </div> */}
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Withdrawal Type</p>
                  <Select
                    value={withdrawalType}
                    id="withdrawalType"
                    className=" profile-border col-7"
                    data-val="true"
                    data-val-required="withdrawal Type is required"
                    onChange={(value) => handleType(value)}
                    style={{ height: "40px", borderRadius: "0px !important" }}
                    options={[
                      {
                        value: "LevelIncome",
                        label: "Greade  Withdrawal",
                      },
                      {
                        value: "PrincipleIncome",
                        label: "Principle Withdrawal",
                      },
                      {
                        value: "OtherIncome",
                        label: "ROC + Royalty + Reward Withdrawal",
                      },
                    ]}
                  />
                </div>
                {withdrawalType === "OtherIncome" ? (
                  <div className="col-11 mt-3 row align-items-center">
                    <p className="col-5 m-0 p-0">Payment Type</p>
                    <Select
                      value={paymentType}
                      id="withdrawalType"
                      className="ms-0 col-7"
                      data-val="true"
                      data-val-required="withdrawal Type is required"
                      onChange={(value) => handlePayment(value)}
                      style={{ height: "40px", borderRadius: "0px !important" }}
                      options={[
                        {
                          value: "USD",
                          label: "USD",
                        },
                        {
                          value: "Token",
                          label: "Token",
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div class="col-11 mt-3 row align-items-center">
                  <p class="col-5 m-0 p-0">Wallet Net USDT Value</p>
                  <input
                    type="text"
                    class="p-2 profile-border col-7"
                    value={Netbal}
                    readOnly
                  />
                </div>

                <div class="col-11 mt-3 row align-items-center">
                  <p class="col-5 m-0 p-0">Enter USD Amount</p>
                  <input
                    type="text"
                    class="p-2 profile-border col-7"
                    value={Amount}
                    onChange={(e) => numberValidation(e.target.value)}
                  />
                </div>
                {(withdrawalType === "LevelIncome" ||
                  withdrawalType === "OtherIncome") &&
                  paymentType !== "USD" && (
                    <div className="col-11 mt-3 row align-items-center">
                      <p className="col-5 m-0 p-0">Token Amount</p>
                      <input
                        type="text"
                        className="p-2 profile-border col-7"
                        value={Token}
                        readOnly
                      />
                    </div>
                  )}

                {/* <div class="col-11 mt-3 row align-items-center">
                  <p class="col-5 m-0 p-0">OTP</p>
                  <input
                    type="text"
                    class="p-2 profile-border col-7"
                    maxlength="10"
                  />
                </div> */}

                <button
                  class="col-5 mt-3 py-3 wdth_btn btn mb-3 activate_btn"
                  onClick={() => Withdrawal_API()}
                  style={{ color: "#fff" }}
                >
                  Proceed
                </button>

                <span style={{ color: "red" }}>
                  * It will take a maximum 24 hours to credit your
                  amount in wallet .
                </span>
              </div>
            </div>
          </div>

          {/* <!-- row closed --> */}
        </div>
        {/* <!-- /Container --> */}
      </div>
    </div>
  );
}

export default Withdrawal;
